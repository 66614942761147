import React, { useEffect } from 'react';
// import useLocalStorage from '../hooks/useLocalStorage';
// see - https://kentcdodds.com/blog/how-to-use-react-context-effectively
// minimises re-renders via specific passing of the context to components
export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();
// required for conetxt
const initialState = {
  isLightTheme: 0,
  timeOfYear: 0,
  information: null
};
// const random = ()=>Math.floor(Math.random()*4)
//
function reducer(state, action) {
  // 
  // console.log('state', state)
  // console.log('action', action)
  // console.log('action.payload.viewer', Boolean(action.payload.viewer))
  switch (action.type) {
    case 'GLOBALCLIENT_WPSTATUS': {
      return {
        ...state,
        information: Boolean(action?.payload?.viewer) ? action.payload : null
      };
    }
    default:
      return {
        state
      };
  }
}
//
const GlobalContextProvider = ({ children }) => {

  const [state, dispatch] = React.useReducer(reducer, initialState);

  // useEffect(() => {
  //   const root = window.document.documentElement;
  //   const initialColorValue = Number(root.style.getPropertyValue('--initial-color-mode'))

  //   if (window) {
  //     // or this will rerender on toggle itself
  //     if (state.timeOfYear === undefined) {
  //       // initial hydate state
  //       dispatch({ type: 'INIT_SEASON', payload: isNaN(initialColorValue) ? 0 : initialColorValue })
  //       // 
  //     } else {
  //     }
  //   }
  // }, [state.timeOfYear])

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
