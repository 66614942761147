module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YQ74R43K0H"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wpcms1.fretharmony.com/graphql","verbose":true,"debug":{"preview":false,"timeBuildSteps":true,"disableCompatibilityCheck":false,"throwRefetchErrors":false,"graphql":{"showQueryVarsOnError":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false}},"production":{"hardCacheMediaFiles":false,"allow404Images":false,"allow401Images":false},"develop":{"nodeUpdateInterval":5000,"hardCacheMediaFiles":true,"hardCacheData":false},"schema":{"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":300000,"perPage":60,"requestConcurrency":25,"previewRequestConcurrency":25},"html":{"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"imageQuality":90,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"RootQuery":{"excludeFieldNames":["viewer","node","schemaMd5"]},"MediaItem":{"lazyNodes":false,"localFile":{"excludeByMimeTypes":[],"maxFileSizeBytes":15728640,"requestConcurrency":10},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true}},"catchLinks":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fretharmony.com","short_name":"fretharmony","start_url":"/","background_color":"#494646","theme_color":"#1e1e1e","display":"standalone","icon":"/opt/build/repo/gatsby-theme-wpfh/content/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"59ab293b3afb45135cf48aa9696ea46e"},
    },{
      plugin: require('../../gatsby-theme-wpfh/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-crafh/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
